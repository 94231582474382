<template>
  <div>
    <div style="display:none">
      <section id="nqs-report-print-table">
        <table
          id="nqs-report-table"
          style="width:100%;font-size:12px !important; padding: 25px;"
        >
          <tr
            border="2"
            style="border-color: #cccccc; border-style: solid; background-color: rgb(208, 255, 248); color: black"
          >
            <th style="text-align:left;">NQS 1</th>
            <th style="text-align:left;">NQS Descriptor</th>
            <th style="text-align:left;">P360 Evidence</th>
          </tr>
          <tbody>
            <tr>
              <td style="vertical-align:top;">
                Element 1.1.1: Approved learning framework
              </td>
              <td style="vertical-align:top;">
                Curriculum decision making contributes to each child's learning
                and development outcomes in relation to their identity,
                connection with community, wellbeing, confidence as learners and
                effectivies as communicators.
              </td>
              <td style="vertical-align:top;">
                The early years learning framework elements considered and implemented across
                <b>{{ centreName }}</b> early learning play space for the past
                <b>{{ timePeriod }}</b> are as follows:
                <br />
                <br />
                <ul v-if="categories.length > 0">
                  <li v-for="(category, key) in categories" :key="key">
                    <strong>{{ category.title }}</strong>
                    <br />
                    <ul class="ml-10">
                      <li
                        style="margin-left:30px!important;text-transform:capitalize;"
                        v-for="(outcome, index) in checkListByCategoryId(
                          category._id
                        )"
                        :key="index + 0.2"
                      >
                        {{
                          outcome.eylfChecklists.title + " - " + outcome.count
                        }}
                        <br />
                      </li>
                    </ul>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td style="vertical-align:top;">Element 1.1.2 Child-centred</td>
              <td style="vertical-align:top;">
                Each Child's current knowledge, strengths, ideas, culture,
                abilities and interests are the foundation of the program
              </td>
              <td style="vertical-align:top;" rowspan="3">
                Completed learning stories and learning cycles, measuring the
                change in children's growth across <strong>{{ coreSkillsCount }}</strong> core
                life such as self-regulation, empathy, agency and content areas
                such as science, environments, reading and arts:
                <br />
                <ul v-if="rooms.length">
                  <li v-for="(room, index) in rooms" :key="index">
                    {{ room.name.toUpperCase() }} - {{ room.completedActivitiesCount }} <br />
                  </li>
                </ul>
                <br />
                <br />

                Total: {{ totalCompletedActivity }} child centred opportunities have been
                offered to guide and enhance the natural development of the
                child at <b> {{ centreName }} </b>over the last
                <b>{{ timePeriod }}</b>
                <br />
                For specific samples of the child-centred learning opporunites
                please follow the link<br />
                <ul v-if="rooms.length">
                  <li v-for="(room, index) in rooms" :key="index">
                    <a
                      :href="
                        `${clientUrl}/center-admin/insights?roomId=${room._id}`
                      "
                    >
                      Insights link to {{ room.name.toUpperCase() }}
                    </a>
                    <br />
                  </li>
                </ul>

                <!-- <ul v-if="rooms.length">
                  <li v-for="(room, index) in rooms" :key="index">
                    <a
                      :href="
                        `${clientUrl}/center-admin/insights?roomId=${room._id}`
                      "
                    >
                      Insights link to {{ room.name.toUpperCase() }}
                    </a>
                    <br />
                  </li>
                </ul> -->
              </td>
            </tr>

            <tr>
              <td style="vertical-align:top;">
                Element 1.1.3 Program learning opporunites
              </td>
              <td style="vertical-align:top;">
                All aspects of the program, including routines, are organised in
                ways that maximise opportunites for each child's learning.
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 1.2.1 Responsive teaching and scaffolding
              </td>
              <td style="vertical-align:top;">
                Educators repond to chlldren's ideas and play and extend
                children's learning through open-ended questions, interactions
                and feedback.
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 1.2.2 Intentional teaching
              </td>
              <td style="vertical-align:top;">
                Educators are deliberate, purposeful, and thoughtful in their
                decisions and actions.
              </td>
              <td style="vertical-align:top;">
                Planning matrices have been developed to measure and monitor our
                teaching team’s deliberate, purposeful and thoughtful decisions
                and action around our children’s play. Currently we have
                <strong>
                  {{ teacherCount ? teacherCount : 0 }} active matrices</strong
                >
                actively holding our team accountable. From these matrices we
                have purposefully been working with children on
                <strong>{{ totalRunningActivity }} activities </strong> to
                support learning. <br />
                Please follow this link to view a selection of our teams
                intentional teaching practices:
                <br />
                <!--<router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                  ><strong> Link to lead teacher matrix</strong>
                </router-link>
                <strong> (Login as a Lead Teacher)</strong>-->
                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=Matrix&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 1.3.1 Assessment and planning cycle
              </td>
              <td style="vertical-align:top;">
                Each child’s learning and development is assessed or evaluated
                as part of an ongoing cycle of observation, analysing learning,
                documentation, planning, implementation and reflection
              </td>
              <td style="vertical-align:top;" rowspan="2">
                Over the past <strong> {{ timePeriod }}</strong> we have
                assessed
                <strong> {{ totalCompletedFollowUp }} follow up</strong> learning
                experiences from our intentional and spontaneous play
                opportunities. To view some samples of our assessment and
                critical reflection please follow this link: <br />
                <!--<router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                  ><strong> Link to Lead Teacher</strong>
                </router-link>
                <strong> (Login as a Lead Teacher)</strong>-->
                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 1.3.2 Critical reflection
              </td>
              <td style="vertical-align:top;">
                Critical reflection on children’s learning and development, both
                as individuals and in groups, drives program planning and
                implementation
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 1.3.3 Information for families
              </td>
              <td style="vertical-align:top;">
                Families are informed about the program and their child's
                progress
              </td>
              <td style="vertical-align:top;">
                <!-- Communication with families is an integral part of
                <strong>{{ centreName }}</strong> everyday work. <br /> -->
                We communicate our teaching and learning practices to families,
                but not limited to, in the following ways:
                <br />
                <ul>
                  <li>
                    <router-link :to="{ name: 'Daily Diary' }" target="_blank"
                      >Link to Daily Diary
                    </router-link>
                    <!--<strong>(Login as a Teacher or Lead Teacher)</strong>-->
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'center-admin-newsletters' }"
                      target="_blank"
                    >
                      Link to Newsletters
                    </router-link>
                  </li>
                </ul>
              </td>
            </tr>
            <tr border="2" style="border-color: #cccccc; border-style: solid;">
              <th style="text-align:left">NQS 2</th>
              <th style="text-align:left">NQS Descriptor</th>
              <th style="text-align:left">P360 Evidence</th>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 2.1.1 Wellbeing and Comfort
              </td>
              <td style="vertical-align:top;">
                Each child’s wellbeing and comfort is provided for, including
                appropriate opportunities to meet each child’s need for sleep,
                rest and relaxation
              </td>
              <td style="vertical-align:top;">
                Being aware of the wellbeing and safety of our children is a
                critical part of everyday. <br />
                Over the past <strong>{{ timePeriod }}</strong> we have provided
                <strong>
                  {{ getCoreSkillActivities("Relational Security") }} RELATIONAL SECURITY experiences
                </strong>
                which have helped wellbeing, becoming and belonging to flourish
                in our children’s lives. To view some samples of our play
                opportunities which promote and stimulate a sense of being,
                becoming and belonging please follow this link:
                <!--(<strong>Link to lead teachers</strong>)-->
                <br />
                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?coreSkill=${safetyCoreskillId}&status=completed&teacher=${leadTeacher._id}&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 2.1.3 Healthy Lifestyle
              </td>
              <td style="vertical-align:top;">
                Healthy eating and physical activity are promoted and
                appropriate for each child.
              </td>
              <td style="vertical-align:top;">
                Being aware of the wellbeing and healthy lifestyle of our
                children is a critical part of everyday routines. <br />
                Over the past <strong>{{ timePeriod }} </strong> we have
                provided
                <strong>
                  {{ getCoreSkillActivities("Agility") }} AGILITY
                  experiences</strong
                >
                to promote and stimulate healthy lifestyle choices and
                influence the children’s habits. To view some samples of our
                play opportunities which promote and stimulate healthy lifestyle
                choices please follow this link:
                <!--<router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                  ><strong> Link to Lead Teacher</strong>
                </router-link>
                <strong>(Login as a Lead Teacher)</strong>-->

                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?coreSkill=${agilityCoreskillId}&status=completed&teacher=${leadTeacher._id}&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr border="2" style="border-color: #cccccc; border-style: solid;">
              <th style="text-align:left">NQS 3</th>
              <th style="text-align:left">NQS Descriptor</th>
              <th style="text-align:left">P360 Evidence</th>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 3.2.1 Inclusive Environment
              </td>
              <td style="vertical-align:top;">
                Outdoor and indoor spaces are organised and adapted to support
                every child's participation and to engage every child in quality
                experiences in both built and natural environments.
              </td>
              <td style="vertical-align:top;">
                In order to truly be inclusive of all children, throughout every
                day, we utilise but are not limited to, the research from
                Howard Gardener to differentiate our play programs so that every
                play/learning modality is given balanced attention.This helps
                all children to build on their natural, curious capacities and
                learn how others play and learn too.
                <br />
                Over the past <strong>{{ timePeriod }}</strong> we have
                consistently and specifically worked alongside children in the
                following learning modes. Ways of play and learning:
                <ul>
                  <li>
                    Nature:
                    <strong
                      >{{ getWaysOfLearningActivities("Nature") }}
                    </strong>
                    <br />
                  </li>
                  <li>
                    Visual:
                    <strong
                      >{{ getWaysOfLearningActivities("Picture") }}
                    </strong>
                    <br />
                  </li>
                  <li>
                    Interpersonal
                    <strong
                      >{{ getWaysOfLearningActivities("People") }}
                    </strong>
                    <br />
                  </li>
                  <li>
                    Intrapersonal:
                    <strong>{{ getWaysOfLearningActivities("Self") }} </strong>
                    <br />
                  </li>
                  <li>
                    Kinaesthetic:
                    <strong>{{ getWaysOfLearningActivities("Body") }} </strong>
                    <br />
                  </li>
                  <li>
                    Musical:
                    <strong>{{ getWaysOfLearningActivities("Music") }} </strong>
                    <br />
                  </li>
                  <li>
                    Logical:
                    <strong>{{ getWaysOfLearningActivities("Logic") }} </strong>
                    <br />
                  </li>
                  <li>
                    Word:
                    <strong>{{ getWaysOfLearningActivities("Word") }} </strong>
                    <br />
                  </li>
                </ul>

                To view some samples of our play opportunities which promote and
                stimulate our inclusive environment please follow this link:
                <br />
                <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong>(Login as a Lead Teacher)</strong> -->

                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?status=completed&teacher=${leadTeacher._id}&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 3.2.3 Environmentally Responsible
              </td>
              <td style="vertical-align:top;">
                The service cares for the environment and supports children to
                become environmentally responsible.
              </td>
              <td style="vertical-align:top;">
                As the environment is our third teacher in our learning and
                playing, we are mindful that we must care, respect and find ways
                to sustain it. Part of our philosophy is to bring this alive for
                children through our play. To view some samples of our
                environmental play opportunities which promote and stimulate our
                love and care for our environment please follow this link:
                <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong> (Login as a Lead Teacher)</strong>.  -->
                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=Matrix&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>

                <br />
                Over the past <strong>{{ timePeriod }}</strong> we have
                consistently and specifically worked alongside children in this
                area and consequently have offered
                <strong
                  >{{ getStreamActivities("Environment") }} ENVIRONMENT
                  activities</strong
                >
                to our children and community.
              </td>
            </tr>
            <tr border="2" style="border-color: #cccccc; border-style: solid;">
              <th style="text-align:left;">NQS 4</th>
              <th style="text-align:left;">NQS Descriptor</th>
              <th style="text-align:left;">P360 Evidence</th>
            </tr>

            <tr>
              <td style="vertical-align:top;">
                Element 4.2.1 Professional Collaboration
              </td>
              <td style="vertical-align:top;">
                Management, educators and staff work with mutual respect and
                collaboratively, and challenge and learn from each other,
                recognising each other’s strengths and skills.
              </td>
              <td style="vertical-align:top;">
                Collaboration empowers our collective thinking and enables us to
                support and challenge children’s learning effectively. Our
                leaders review our professional learning and our documentation
                alongside us to help improve our professional capacity.
                Currently, we have
                <strong>{{ adminList.length }} </strong> leaders who are
                collaborating with
                <strong>{{ teacherCount ? teacherCount : 0 }}</strong> team
                members across our centre. These leaders have collaborated on
                <strong>{{ totalCompletedActivity }} </strong>
                individual pieces of documentation over the past
                <strong>{{ timePeriod }}</strong
                >. This collaboration has had marked influence on our team’s
                work life including, but not limited to, planning, professional
                development and high-quality documentation. To view some of this
                growth please consider the following learning story samples:
                <!-- <ul>
                <li
                  v-for="(activity, key) in getTopThreeCompletedActivities()"
                  :key="key"
                >
                  <strong>{{ activity.name }}</strong>
                </li>
              </ul> -->

                <ul v-if="leadTeachers.length">
                  <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                    <a
                      :href="
                        `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&learning-story-type=mostrecent&start=${startDate}&end=${endDate}`
                      "
                    >
                      {{ leadTeacher.fullName }}
                    </a>
                    <br />
                  </li>
                </ul>
                To view a sample of the professional development logs please
                follow the following link:
                <router-link :to="{ name: 'Self Assessment' }" target="_blank"
                  ><strong> PD logs for the lead teachers</strong>
                </router-link>
               (Or Login as a Lead Teacher).
              </td>
            </tr>
            <tr border="2" style="border-color: #cccccc; border-style: solid;">
              <th style="text-align:left;">NQS 5</th>
              <th style="text-align:left;">NQS Descriptor</th>
              <th style="text-align:left;">P360 Evidence</th>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 5.1.1 Positive educator to child interactions
              </td>
              <td style="vertical-align:top;">
                Responsive and meaningful interactions build trusting
                relationships which engage and support each child to feel
                secure, confident and included.
              </td>
              <td style="vertical-align:top;">
                One of our core life skills is Relational Security. When
                children have a safe base to explore play and a secure haven to
                return to when a place of emotional attachment is needed, they
                will thrive. A sense of safety is critical to the successful
                development of the mind. It is also incredibly significant in
                the development of wellbeing saturating every aspect of a young
                person’s life. Our aim is to help each child identify and
                articulate who they have a safe base with and where they can
                find a secure haven.
                <br />
                Over the past <strong>{{ timePeriod }}</strong> we have
                specifically worked with children
                <strong
                  >{{ getCoreSkillActivities("Relational Security") }} times
                  using the core skill RELATIONAL SECURITY</strong
                >. This focus has meant that our community has observed a
                remarkable shift towards the development of responsive and
                meaningful interactions that build trusting relationships which
                engage and support each child to feel secure, confident and
                included. <br />
                To view a sample of these interactions please follow these
                links:
                <ul>
                  <li
                    v-if="
                      getCoreSkillActivities('Relational Security', 7).length >
                        0
                    "
                    v-for="(activity, key) in getCoreSkillActivities(
                      'Relational Security',
                      7
                    )"
                    :key="key"
                  >
                      <router-link :to="{ name: 'center-learning-story-activity' , params: { activityId: activity._id }}" target="_blank"
                >{{ activity.name }}</router-link>
                    <br />
                  </li>
                  <li
                    v-if="
                      getCoreSkillActivities('Relational Security', 7).length <
                        1
                    "
                  >
                    no data found
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 5.2.1 Collaborative learning
              </td>
              <td style="vertical-align:top;">
                Children are supported to collaborate, learn from and help each
                other
              </td>
              <td style="vertical-align:top;">
                One of our core life skills is Reciprocal Play: When children
                are engaged in positive social interactions, they are learning
                the values, skills and knowledge that enables them to relate to
                others effectively and positively. Consequently, children build
                a sense of who they are in the world, and their place in it. A
                focus on social development, involves children’s ability to
                interact with others, and regulate their own behaviours. <br />
                Over the past <strong>{{ timePeriod }}</strong> we have
                specifically worked with children
                <strong
                  >{{ getCoreSkillActivities("Reciprocal Play") }} times using
                  core skills RECIPROCAL PLAY.</strong
                >. This focus has meant that our community has observed a
                remarkable shift towards the development of children being
                supported to collaborate, learn from and help each other. To
                view a sample of these interactions please follow these links:
                <br />
                <ul>
                  <li
                    v-if="
                      getCoreSkillActivities('Reciprocal Play', 3).length > 0
                    "
                    v-for="(activity, key) in getCoreSkillActivities(
                      'Reciprocal Play',
                      3
                    )"
                    :key="key"
                  >
                   <router-link :to="{ name: 'center-learning-story-activity' , params: { activityId: activity._id }}" target="_blank"
                >{{ activity.name }}</router-link>
                    <br />
                  </li>
                  <li v-else>no data found</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">Element 5.2.2 Self-regulation</td>
              <td style="vertical-align:top;">
                Each child is supported to regulate their own behaviour, respond
                appropriately to the behaviour of others and communicate
                effectively to resolve conflicts.
              </td>
              <td style="vertical-align:top;">
                One of our core life skills is Self-Regulation. When children
                utilise their skills of self-regulation, they are encouraged to
                take turns, share, develop patience, compassion, and
                understanding. Self-regulation helps children to regulate
                reactions to emotions like frustration or excitement, calm down
                after something exciting or upsetting, focus on a task, and
                re-focus. <br />
                Over the past <strong>{{ timePeriod }}</strong> we have
                specifically worked with children
                <strong
                  >{{ getCoreSkillActivities("Self Regulation") }} times using
                  the core skill SELF REGULATION</strong
                >. This focus has meant that our community has observed a
                remarkable shift towards the development of children being
                supported to regulate their own behaviour, respond appropriately
                to the behaviour of others and communicate effectively to
                resolve conflicts. <br />
                <!-- To view a sample of these interactions please follow these
                links: <br /> -->
                <ul>
                  <li
                    v-if="
                      getCoreSkillActivities('Self Regulation', 7).length > 0
                    "
                    v-for="(activity, key) in getCoreSkillActivities(
                      'Self Regulation',
                      7
                    )"
                    :key="key"
                  >
                    <router-link :to="{ name: 'center-learning-story-activity' , params: { activityId: activity._id }}" target="_blank"
                    >{{ activity.name }}</router-link>
                  </li>
                  <li v-else>no data found</li>
                </ul>
              </td>
            </tr>
            <tr border="2" style="border-color: #cccccc; border-style: solid;">
              <th style="text-align:left;">NQS 6</th>
              <th style="text-align:left;">NQS Descriptor</th>
              <th style="text-align:left;">P360 Evidence</th>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 6.1.2 Parent views are respected
              </td>
              <td style="vertical-align:top;">
                The expertise, culture, values and beliefs of families are
                respected, and families share in decision-making about their
                child’s learning and wellbeing.
              </td>
              <td style="vertical-align:top;">
                <!-- The wisdom and insight of our families forms the foundation of
                our relationships with children. We value their insights,
                decision-making and cultural contexts so much so that this is
                our starting pointing when families enrol their children with
                us. <br /> -->
                Through a series of responses and insights from parents and
                significant others in a child’s life we gather background
                information which enables us to truly learn about their family
                unit. <br />
                Over the past <strong>{{ timePeriod }}</strong> we have
                collected
                <strong >{{childStory ? childStory.storyCount: 0 }}
                  child’s stories</strong
                >
                and have directly responded to
                <strong >{{ childStory ? childStory.responseCount : 0 }} staff
                  responses to child’s stories</strong >.
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 6.2 Collaborative Partnerships
              </td>
              <td style="vertical-align:top;">
                Collaborative partnerships enhance children’s inclusion,
                learning and wellbeing.
              </td>
              <td style="vertical-align:top;">
                We use <strong>{{fastChecks.length}}</strong> domains to support children’s inclusion, learning and
                wellbeing. These are Vision, Speech, Hearing, Language, Agility
                and Wellbeing. <br />
                We have currently completed a number of checks across all these domains as follows:<br />
                <ul>
                  <li v-for="(fastCheck, key) in fastChecks" :key="key">
                    {{ fastCheck.name }},
                    <strong>{{
                      fastCheck.childFastCheck
                        ? fastCheck.childFastCheck.total
                        : 0
                    }}</strong>
                    <br />
                  </li>
                </ul>
                Consequently, we have been able to specifically support
                <strong>{{ childWithFastchecksScore }}</strong> children’s
                development across the <strong>{{fastChecks.length}}</strong> domains rather than allow them to fall through any
                gaps. We have also supported and guided
                <strong>
                  {{ dynamicText }}
                </strong>
                families with specific parenting ideas which meet their
                children’s needs and/or offer referrals to Speech therapist,
                Occupational Therapists and child psychologists as required.
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 6.2.2 Access and participation.
              </td>
              <td style="vertical-align:top;">
                Effective partnerships support children's access, inclusion and
                participation in the program
              </td>
              <td style="vertical-align:top;">
                As each family joins our community, we ask the primary care
                givers to share their child’s story through a series of
                questions. Each of the child’s significant care givers, are
                asked to share insights, to help build a strong and
                insightful partnership between home and
                <strong>{{ centreName }}</strong
                >. Each of the child’s educators at
                <strong>{{ centreName }}</strong> then responds to these stories
                to help a reciprocal connection to be formed. <br />
                We currently have
                <strong>
                  {{ childStory ? childStory.storyCount : 0 }}
                </strong>
                across our centre, which has deepened our knowledge of values
                and beliefs of families. <br />
                Please review a sample story and teacher response
                <br />
                <ul>
                  <li
                    v-if="childStory"
                    v-for="(child, key) in childStory.children"
                    :key="key"
                  >
                    <strong>{{ child.fullName }}</strong>
                    <br />
                  </li>
                  <li v-if="childStory.length < 1">
                    <strong>No data found.</strong>
                  </li>
                </ul>
              </td>
            </tr>
            <tr border="2" style="border-color: #cccccc; border-style: solid;">
              <th style="text-align:left;">NQS 7</th>
              <th style="text-align:left;">NQS Descriptor</th>
              <th style="text-align:left;">P360 Evidence</th>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 7.2.1 Continuous Improvement
              </td>
              <td style="vertical-align:top;">
                There is an effective self-assessment and quality improvement
                process in place.
              </td>
              <td style="vertical-align:top;">
                One of the ways we maintain momentum around continuous
                improvement is through self-assessment across <strong>{{ coreSkillsCount }}</strong> core life
                skills. These skills are: 
                {{coreSkillsName}}. <br />
                We review our capacity building each quarter in each core skill
                and set our professional course according to the outcome of the
                self-assessment. <br />
                Currently our team has completed
                <br />
                <ul>
                  <li v-for="(room, key) in selfAssessmentRooms" :key="key">
                    <strong>
                      {{ room.name }} -
                      {{ room.totalSelfAssessmentCount }}</strong
                    >
                    <br />
                  </li>
                </ul>
                self-assessments and this has seen a marked improvement in our
                interactions with children around their wellbeing.
              </td>
            </tr>
            <tr>
              <td style="vertical-align:top;">
                Element 7.2.3 Development of professionals
              </td>
              <td style="vertical-align:top;">
                Educators, co-ordinators and staff members’ performance is
                regularly evaluated, and individual plans are in place to
                support learning and development.
              </td>
              <td style="vertical-align:top;">
                Professional development is vital to our team’s growth and the
                children’s success. We have over the past
                <strong>{{ timePeriod }}</strong> provided, but not limited
                to
                <strong>{{ developmentLogsCount }}</strong>
                opportunities with 100% engagement and observable outcomes.
                <br />
                <!-- To view a sample of the professional development logs please
                follow the following link: <br /> -->
                <router-link :to="{ name: 'Self Assessment' }" target="_blank"
                  ><strong> PD logs for the lead teachers</strong>
                </router-link>
                (Or Login as a Lead Teacher).
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <vx-card
      class="mb-base"
      style="display: inline-block; width: 100%; padding-bottom: 35px;"
    >
      <div class="vx-breadcrumb md:block hidden mb-4">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <a href="javascript:void(0)" class>Manage</a>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7" />
                  <polyline points="6 17 11 12 6 7" />
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>NQS Report</a>
          </li>
        </ul>
      </div>

      <h2 class="mb-4">NQS Report</h2>

      <div class="flex flex-wrap items-center my-8">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
        >
          <vs-col>
            <div class="datepicker-wrapper hasIcon">
              <datepicker
                :minimumView="'month'"
                :maximumView="'month'"
                icon-after="true"
                icon="calendar_today"
                icon-pack="feather"
                :format="dateFormat"
                v-model="startDate"
                class="w-full"
                placeholder="Select Start Month"
                :value="startDate"
              ></datepicker>
              <vs-icon icon="calendar_today" class="icon"></vs-icon>
            </div>
          </vs-col>
        </vs-row>

        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
        >
          <vs-col>
            <div class="datepicker-wrapper hasIcon">
              <datepicker
                :minimumView="'month'"
                :maximumView="'month'"
                icon-after="true"
                icon="calendar_today"
                icon-pack="feather"
                :format="dateFormat"
                v-model="endDate"
                class="w-full"
                placeholder="Select End Month"
                :value="endDate"
              ></datepicker>
              <vs-icon icon="calendar_today" class="icon"></vs-icon>
            </div>
          </vs-col>
        </vs-row>
        <!--        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-2">-->
        <!--          <vs-col>-->
        <!--            <vs-button-->
        <!--              class="w-full mr-2 md:w-auto float-right"-->
        <!--              color="primary"-->
        <!--              type="filled"-->
        <!--              @click.stop="editText()"-->
        <!--            >-->
        <!--              Edit Text-->
        <!--            </vs-button>-->
        <!--          </vs-col>-->
        <!--        </vs-row>-->
      </div>

      <table>
        <thead>
          <tr>
            <th>NQS 1</th>
            <th>NQS Descriptor</th>
            <th>P360 Evidence</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Element 1.1.1: Approved learning framework
            </td>
            <td>
              Curriculum decision making contributes to each child's learning
              and development outcomes in relation to their identity, connection
              with community, wellbeing, confidence as learners and effectivies
              as communicators.
            </td>
            <td>
              The early years learning framework elements considered and implemented across
              <b>{{ centreName }}</b> early learning play space for the past
              <b>{{ timePeriod }}</b> are as follows:
              <br />
              <br />

              <ul v-if="categories.length > 0">
                <li v-for="(category, key) in categories" :key="key">
                  <strong>{{ category.title }}</strong>

                  <ul class="ml-10">
                    <li
                      v-for="(outcome, index) in checkListByCategoryId(
                        category._id
                      )"
                      :key="index + 0.2"
                    >
                      {{ outcome.eylfChecklists.title + " - " + outcome.count }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Element 1.1.2 Child-centred</td>
            <td>
              Each Child's current knowledge, strengths, ideas, culture,
              abilities and interests are the foundation of the program
            </td>
            <td rowspan="3">
              Completed learning stories and learning cycles, measuring the
              change in children's growth across
              <strong>{{ coreSkillsCount}}</strong> core life such as self-regulation,
              empathy, agency and content areas such as science, environments,
              reading and arts:
              <br />

              <ul v-if="rooms.length">
                <li v-for="(room, index) in rooms" :key="index">
                  {{ room.name.toUpperCase() }} - {{ room.completedActivitiesCount }}
                </li>
              </ul>
              <br />
              <br />

              Total: {{ totalCompletedActivity }} child centred opportunities have been
              offered to guide and enhance the natural development of the child
              at <b> {{ centreName }} </b>over the last <b>{{ timePeriod }}</b>
              <br />
              For specific samples of the child-centred learning opporunites
              please follow the link
              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>
              <!-- <ul v-if="rooms.length">
                <li v-for="(room, index) in rooms" :key="index">
                  <router-link
                    :to="{
                      name: 'center-admin-insights',
                      query: { roomId: room._id }
                    }"
                    >Insights link to
                    {{ room.name.toUpperCase() }}
                  </router-link>
                </li>
              </ul> -->
            </td>
          </tr>

          <tr>
            <td>Element 1.1.3 Program learning opporunites</td>
            <td>
              All aspects of the program, including routines, are organised in
              ways that maximise opportunites for each child's learning.
            </td>
          </tr>

          <tr>
            <td>Element 1.2.1 Responsive teaching and scaffolding</td>
            <td>
              Educators repond to chlldren's ideas and play and extend
              children's learning through open-ended questions, interactions and
              feedback.
            </td>
          </tr>
          <tr>
            <td>Element 1.2.2 Intentional teaching</td>
            <td>
              Educators are deliberate, purposeful, and thoughtful in their
              decisions and actions.
            </td>
            <td>
              Planning matrices have been developed to measure and monitor our
              teaching team’s deliberate, purposeful and thoughtful decisions
              and action around our children’s play. Currently we have
              <strong> {{ teacherCount ? teacherCount : 0 }} </strong>actively
              holding our team accountable. From these matrices we have
              purposefully been working with children on
              <strong>{{ totalRunningActivity }} activities </strong> to
              support learning. <br />
              Please follow this link to view a selection of our teams
              intentional teaching practices:
              <br />

              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=Matrix&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>

              <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to lead teacher matrix</strong>
              </router-link>
              <strong> (Login as a Lead Teacher)</strong> -->
            </td>
          </tr>
          <tr>
            <td>Element 1.3.1 Assessment and planning cycle</td>
            <td>
              Each child’s learning and development is assessed or evaluated as
              part of an ongoing cycle of observation, analysing learning,
              documentation, planning, implementation and reflection
            </td>
            <td>
              Over the past <strong>{{ timePeriod }}</strong> we have assessed
              <strong> {{ totalCompletedFollowUp }} follow ups</strong> learning
              experiences from our intentional and spontaneous play
              opportunities. To view some samples of our assessment and critical
              reflection please follow this link: <br />
              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>
              <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong> (Login as a Lead Teacher)</strong> -->
            </td>
          </tr>
          <tr>
            <td>Element 1.3.2 Critical reflection</td>
            <td>
              Critical reflection on children’s learning and development, both
              as individuals and in groups, drives program planning and
              implementation
            </td>
          </tr>
          <tr>
            <td>
              Element 1.3.3 Information for families
            </td>
            <td>
              Families are informed about the program and their child's progress
            </td>
            <td>
              <!--Communication with families is an integral part of
                <strong>{{ centreName }}</strong> everyday work. <br />-->
              We communicate our teaching and learning practices to families,
              but not limited to, in the following ways:
              <!--            (<strong>insert link to Daily Diary, Newsletters and a sample children’s-->
              <!--              dashboard</strong>)-->
              <br />
              <ul>
                <li>
                  <router-link :to="{ name: 'Daily Diary' }" target="_blank"
                    >Link to Daily Diary
                  </router-link>
                  <!--<strong>(Login as a Teacher or Lead Teacher)</strong>-->
                </li>
                <li>
                  <router-link
                    :to="{ name: 'center-admin-newsletters' }"
                    target="_blank"
                  >
                    Link to Newsletters
                  </router-link>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>NQS 2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Element 2.1.1 Wellbeing and Comfort
            </td>
            <td>
              Each child’s wellbeing and comfort is provided for, including
              appropriate opportunities to meet each child’s need for sleep,
              rest and relaxation
            </td>
            <td>
              Being aware of the wellbeing and safety of our children is a
              critical part of everyday. <br />
              Over the past <strong>{{ timePeriod }}</strong> we have provided
              <strong>
                {{ getCoreSkillActivities("Relational Security") }} RELATIONAL SECURITY experiences
              </strong>
              which have helped wellbeing, becoming and belonging to flourish in
              our children’s lives. To view some samples of our play
              opportunities which promote and stimulate a sense of being,
              becoming and belonging please follow this link:
              <br />
              <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong>(Login as a Lead Teacher)</strong> -->
              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?coreSkill=${safetyCoreskillId}&status=completed&teacher=${leadTeacher._id}&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Element 2.1.3 Healthy Lifestyle
            </td>
            <td>
              Healthy eating and physical activity are promoted and appropriate
              for each child.
            </td>
            <td>
              Being aware of the wellbeing and healthy lifestyle of our children
              is a critical part of everyday routines.
              <br />
              Over the past <strong>{{ timePeriod }} </strong> we have provided
              <strong>
                {{ getCoreSkillActivities("Agility") }} AGILITY
                experiences</strong
              >
              to promote and stimulate healthy lifestyle choices and
              influence the children’s habits. To view some samples of our play
              opportunities which promote and stimulate healthy lifestyle
              choices please follow this link:
              <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong>(Login as a Lead Teacher)</strong> -->
              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?coreSkill=${agilityCoreskillId}&status=completed&teacher=${leadTeacher._id}&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>NQS 3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Element 3.2.1 Inclusive Environment
            </td>
            <td>
              Outdoor and indoor spaces are organised and adapted to support
              every child's participation and to engage every child in quality
              experiences in both built and natural environments.
            </td>
            <td>
              In order to truly be inclusive of all children, throughout every
              day, we utilise but are not limited to, the research from Howard
              Gardener to differentiate our play programs so that every
              play/learning modality is given balanced attention.This helps all
              children to build on their natural, curious capacities and learn
              how others play and learn too.
              <br />
              Over the past <strong>{{ timePeriod }}</strong> we have
              consistently and specifically worked alongside children in the
              following learning modes. Ways of play and learning:
              <ul>
                <li>
                  Nature:
                  <strong>{{ getWaysOfLearningActivities("Nature") }} </strong>
                </li>
                <li>
                  Visual:
                  <strong>{{ getWaysOfLearningActivities("Picture") }} </strong>
                </li>
                <li>
                  Interpersonal
                  <strong>{{ getWaysOfLearningActivities("People") }} </strong>
                </li>
                <li>
                  Intrapersonal:
                  <strong>{{ getWaysOfLearningActivities("Self") }} </strong>
                </li>
                <li>
                  Kinaesthetic:
                  <strong>{{ getWaysOfLearningActivities("Body") }} </strong>
                </li>
                <li>
                  Musical:
                  <strong>{{ getWaysOfLearningActivities("Music") }} </strong>
                </li>
                <li>
                  Logical:
                  <strong>{{ getWaysOfLearningActivities("Logic") }} </strong>
                </li>
                <li>
                  Word:
                  <strong>{{ getWaysOfLearningActivities("Word") }} </strong>
                </li>
              </ul>

              To view some samples of our play opportunities which promote and
              stimulate our inclusive environment please follow this link:
              <br />
              <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong>(Login as a Lead Teacher)</strong> -->

              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?status=completed&teacher=${leadTeacher._id}&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Element 3.2.3 Environmentally Responsible</td>
            <td>
              The service cares for the environment and supports children to
              become environmentally responsible.
            </td>
            <td>
              As the environment is our third teacher in our learning and
              playing, we are mindful that we must care, respect and find ways
              to sustain it. Part of our philosophy is to bring this alive for
              children through our play. To view some samples of our
              environmental play opportunities which promote and stimulate our
              love and care for our environment please follow this link: <br />
              <!-- <router-link :to="{ name: 'teacher-dashboard' }" target="_blank"
                ><strong> Link to Lead Teacher</strong>
              </router-link>
              <strong> (Login as a Lead Teacher)</strong>.  -->
              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=Matrix&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>

              <br />
              Over the past <strong>{{ timePeriod }}</strong> we have
              consistently and specifically worked alongside children in this
              area and consequently have offered
              <strong
                >{{ getStreamActivities("Environment") }} ENVIRONMENT
                activities</strong
              >
              to our children and community.
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>
              NQS 4
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Element 4.2.1 Professional Collaboration</td>
            <td>
              Management, educators and staff work with mutual respect and
              collaboratively, and challenge and learn from each other,
              recognising each other’s strengths and skills.
            </td>
            <td>
              Collaboration empowers our collective thinking and enables us to
              support and challenge children’s learning effectively. Our leaders
              review our professional learning and our documentation alongside
              us to help improve our professional capacity. Currently, we have
              <strong>{{ adminList.length }} </strong> leaders who are
              collaborating with
              <strong>{{ teacherCount ? teacherCount : 0 }}</strong> team
              members across our centre. These leaders have collaborated on
              <strong>{{ totalCompletedActivity }}</strong>
              individual pieces of documentation over the past
              <strong>{{ timePeriod }}</strong
              >. This collaboration has had marked influence on our team’s work
              life including, but not limited to, planning, professional
              development and high-quality documentation. To view some of this
              growth please consider the following learning story samples: <br />
              <!-- <ul>
                <li
                  v-for="(activity, key) in getTopThreeCompletedActivities()"
                  :key="key"
                >
                  <strong>{{ activity.name }}</strong>
                </li>
              </ul> -->

              <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&learning-story-type=mostrecent&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul>

              To view a sample of the professional development logs please
              follow the following link:
              <router-link :to="{ name: 'Self Assessment' }" target="_blank"
                ><strong> PD logs for the lead teachers</strong>
              </router-link>
              (Or Login as a Lead Teacher).
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>
              NQS 5
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Element 5.1.1 Positive educator to child interactions</td>
            <td>
              Responsive and meaningful interactions build trusting
              relationships which engage and support each child to feel secure,
              confident and included.
            </td>
            <td>
              One of our core life skills is Relational Security. When children
              have a safe base to explore play and a secure haven to return to
              when a place of emotional attachment is needed, they will thrive.
              A sense of safety is critical to the successful development of the
              mind. It is also incredibly significant in the development of
              wellbeing saturating every aspect of a young person’s life. Our
              aim is to help each child identify and articulate who they have a
              safe base with and where they can find a secure haven.
              <br />
              Over the past <strong>{{ timePeriod }}</strong> we have
              specifically worked with children
              <strong
                >{{ getCoreSkillActivities("Relational Security") }} times using
                the core skill RELATIONAL SECURITY</strong
              >. This focus has meant that our community has observed a
              remarkable shift towards the development of responsive and
              meaningful interactions that build trusting relationships which
              engage and support each child to feel secure, confident and
              included. <br />
              To view a sample of these interactions please follow these links:

<ul>
                  <li
                    v-if="
                      getCoreSkillActivities('Relational Security', 7).length >
                        0
                    "
                    v-for="(activity, key) in getCoreSkillActivities(
                      'Relational Security',
                      7
                    )"
                    :key="key"
                  >
                      <router-link :to="{ name: 'center-learning-story-activity' , params: { activityId: activity._id }}" target="_blank"
                         >{{ activity.name }}</router-link>
                    <br />
                  </li>
                  <li
                    v-if="
                      getCoreSkillActivities('Relational Security', 7).length <
                        1
                    "
                  >
                    no data found
                  </li>
                </ul>
              <!-- <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&coreSkill=${relationalCoreskillId}&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul> -->

              <!-- <ul>
                <li
                  v-if="
                    getCoreSkillActivities('Relational Security', 7).length > 0
                  "
                  v-for="(activity, key) in getCoreSkillActivities(
                    'Relational Security',
                    7
                  )"
                  :key="key"
                >
                  <strong>{{ activity.name }}</strong>
                </li>
                <li
                  v-if="
                    getCoreSkillActivities('Relational Security', 7).length < 1
                  "
                >
                  no data found
                </li>
              </ul> -->
            </td>
          </tr>
          <tr>
            <td>
              Element 5.2.1 Collaborative learning
            </td>
            <td>
              Children are supported to collaborate, learn from and help each
              other
            </td>
            <td>
              One of our core life skills is Reciprocal Play: When children are
              engaged in positive social interactions, they are learning the
              values, skills and knowledge that enables them to relate to others
              effectively and positively. Consequently, children build a sense
              of who they are in the world, and their place in it. A focus on
              social development, involves children’s ability to interact with
              others, and regulate their own behaviours. <br />
              Over the past <strong>{{ timePeriod }}</strong> we have
              specifically worked with children
              <strong
                >{{ getCoreSkillActivities("Reciprocal Play") }} times using the
                core skill RECIPROCAL PLAY</strong
              >. This focus has meant that our community has observed a
              remarkable shift towards the development of children being
              supported to collaborate, learn from and help each other. To view
              a sample of these interactions please follow these links: <br />
              <!-- <ul>
                <li
                  v-if="getCoreSkillActivities('Reciprocal Play', 3).length > 0"
                  v-for="(activity, key) in getCoreSkillActivities(
                    'Reciprocal Play',
                    3
                  )"
                  :key="key"
                >
                  <strong>{{ activity.name }}</strong>
                </li>
                <li v-else>no data found</li>
              </ul> -->
              <ul>
                  <li
                    v-if="
                      getCoreSkillActivities('Reciprocal Play', 3).length > 0
                    "
                    v-for="(activity, key) in getCoreSkillActivities(
                      'Reciprocal Play',
                      3
                    )"
                    :key="key"
                  >
                   <router-link :to="{ name: 'center-learning-story-activity' , params: { activityId: activity._id }}" target="_blank"
                >{{ activity.name }}</router-link>
                    <br />
                  </li>
                  <li v-else>no data found</li>
                </ul>
              <!-- <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&coreSkill=${reciprocalPlayCoreskillId}&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul> -->
            </td>
          </tr>
          <tr>
            <td>Element 5.2.2 Self-regulationvvv</td>
            <td>
              Each child is supported to regulate their own behaviour, respond
              appropriately to the behaviour of others and communicate
              effectively to resolve conflicts.
            </td>
            <td>
              One of our core life skills is Self-Regulation: When children
              utilise their skills of self-regulation, they are encouraged to
              take turns, share, develop patience, compassion, and
              understanding. Self-regulation helps children to regulate
              reactions to emotions like frustration or excitement, calm down
              after something exciting or upsetting, focus on a task, and
              re-focus.
              <br />
              Over the past <strong>{{ timePeriod }}</strong> we have
              specifically worked with children
              <strong
                >{{ getCoreSkillActivities("Self Regulation") }} times using
                the core skill SELF REGULATION</strong
              >. This focus has meant that our community has observed a
              remarkable shift towards the development of children being
              supported to regulate their own behaviour, respond appropriately
              to the behaviour of others and communicate effectively to resolve
              conflicts. <br />

               <ul>
                  <li
                    v-if="
                      getCoreSkillActivities('Self Regulation', 7).length > 0
                    "
                    v-for="(activity, key) in getCoreSkillActivities(
                      'Self Regulation',
                      7
                    )"
                    :key="key"
                  >
                    <router-link :to="{ name: 'center-learning-story-activity' , params: { activityId: activity._id }}" target="_blank"
                    >{{ activity.name }}</router-link>
                  </li>
                  <li v-else>no data found</li>
                </ul>

              <!-- teacher list with link -->
              <!-- To view a sample of these interactions please follow these links:
              <br /> -->
              <!-- <ul v-if="leadTeachers.length">
                <li v-for="(leadTeacher, index) in leadTeachers" :key="index">
                  <a
                    :href="
                      `/center-admin/learning-story?teacher=${leadTeacher._id}&displayView=List&status=completed&coreSkill=${selfRegulationCoreskillId}&start=${startDate}&end=${endDate}`
                    "
                  >
                    {{ leadTeacher.fullName }}
                  </a>
                  <br />
                </li>
              </ul> -->
              <!-- <ul>
                <li
                  v-if="
                    getCoreSkillActivities('Self Regulations', 7).length > 0
                  "
                  v-for="(activity, key) in getCoreSkillActivities(
                    'Self Regulations',
                    7
                  )"
                  :key="key"
                >
                  <strong>{{ activity.name }}</strong>
                </li>
                <li v-else>no data found</li>
              </ul> -->
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>NQS 6</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Element 6.1.2 Parent views are respected</td>
            <td>
              The expertise, culture, values and beliefs of families are
              respected, and families share in decision-making about their
              child’s learning and wellbeing.
            </td>
            <td>
              The wisdom and insight of our families forms the foundation of our
              relationships with children. We value their insights,
              decision-making and cultural contexts so much so that this is our
              starting pointing when families enrol their children with us.
              <br />
              <br />
              Through a series of responses and insights from parents and
              significant others in a child’s life we gather background
              information which enables us to truly learn about their family
              unit. <br />
              Over the past <strong>{{ timePeriod }}</strong> we have collected

              <strong
                >{{childStory ? childStory.storyCount : 0}}
                child’s stories
              </strong>
              and have directly responded to
              <strong
                >{{childStory ? childStory.responseCount : 0 }}
                staff responses to child’s stories</strong
              >.
            </td>
          </tr>
          <tr>
            <td>Element 6.2 Collaborative Partnerships</td>
            <td>
              Collaborative partnerships enhance children’s inclusion, learning
              and wellbeing.
            </td>
            <td>
              We use <strong>{{fastChecks.length}}</strong> domains to support children’s inclusion, learning and
              wellbeing. These are Vision, Speech, Hearing, Language, Agility
              and Wellbeing. <br />
              We have currently completed a number of checks across all these domains as follows:<br />
              <ul>
                <li v-for="(fastCheck, key) in fastChecks" :key="key">
                  {{ fastCheck.name }},
                  <strong>{{
                    fastCheck.childFastCheck
                      ? fastCheck.childFastCheck.total
                      : 0
                  }}</strong>
                </li>
              </ul>
              Consequently, we have been able to specifically support
              <strong>{{ childWithFastchecksScore }}</strong> children’s
              development across the <strong>{{fastChecks.length}}</strong> domains rather than allow them to fall through any
              gaps. We have also supported and guided (<strong>
                {{ dynamicText }}
                <span @click="editText" style="color: #289ad5;"
                  >EDIT</span
                > </strong
              >) families with specific parenting ideas which meet their
              children’s needs and/or offer referrals to Speech therapist,
              Occupational Therapists and child psychologists as required.
            </td>
          </tr>
          <tr>
            <td>
              Element 6.2.2 Access and participation.
            </td>
            <td>
              Effective partnerships support children's access, inclusion and
              participation in the program
            </td>
            <td>
              As each family joins our community, we ask the primary care givers
              to share their child’s story through a series of questions. Each
              of the child’s educators are asked to share insights, to help
              build a strong and insightful partnership between home and
              <strong>{{ centreName }}</strong
              >. Each of the child’s primary care giver at
              <strong>{{ centreName }}</strong> then responds to these stories
              to help a reciprocal connection to be formed. <br />

              We currently have
              <strong>
                {{ childStory ? childStory.storyCount : 0 }}
              </strong>
              across our centre, which has deepened our knowledge of values and
              beliefs of families.
              <br />
              Please review a sample story and teacher response
              <br />
              <ul>
                <li
                  v-if="childStory"
                  v-for="(child, key) in childStory.children"
                  :key="key"
                >
                  <strong>{{ child.fullName }}</strong>
                </li>
                <li v-if="!childStory">
                  <strong>No data found.</strong>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>
              NQS 7
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Element 7.2.1 Continuous Improvement</td>
            <td>
              There is an effective self-assessment and quality improvement
              process in place.
            </td>
            <td>
              One of the ways we maintain momentum around continuous improvement
              is through self-assessment across <strong>{{ coreSkillsCount }}</strong> core life skills. These skills
              are: {{coreSkillsName}}. <br />
              We review our capacity building each quarter in each core skill
              and set our professional course according to the outcome of the
              self-assessment. <br />
              Currently our team has completed
              <br />
              <ul>
                <li v-for="(room, key) in selfAssessmentRooms" :key="key">
                  <strong>
                    {{ room.name }} -
                    {{ room.totalSelfAssessmentCount }}</strong
                  >
                </li>
              </ul>
              self-assessments and this has seen a marked improvement in our
              interactions with children around their wellbeing.
            </td>
          </tr>
          <tr>
            <td>
              Element 7.2.3 Development of professionals
            </td>
            <td>
              Educators, co-ordinators and staff members’ performance is
              regularly evaluated, and individual plans are in place to support
              learning and development.
            </td>
            <td>
              Professional development is vital to our team’s growth and the
              children’s success. We have over the past
              <strong>{{ timePeriod }}</strong> provided, but not limited
              to
              <strong>{{ developmentLogsCount }}</strong>
              opportunities with 100% engagement and observable outcomes. <br />
              To view a sample of the professional development logs please
              follow the following link: <br />
              <router-link :to="{ name: 'Self Assessment' }" target="_blank"
                ><strong> PD logs for the lead teachers</strong>
              </router-link>
              (Or Login as a Lead Teacher).
            </td>
          </tr>
        </tbody>
      </table>

      <div class="w-full mt-10 md:w-1/4" style="margin-left: -35px;">
        <vs-button
          class="w-full ml-10 md:w-auto"
          color="primary"
          type="filled"
          @click.stop="generatePrint()"
        >
           Print or Save to PDF
        </vs-button>
        <!--<vs-button
          class="w-full mr-2 md:w-auto float-right"
          color="danger"
          type="filled"
          @click.stop="generatePrint()"
        >
          Print
        </vs-button>-->
      </div>
    </vx-card>
    <vs-popup
      class="holamundo add-children-popup"
      title
      :active.sync="editTextPopup"
    >
      <form class="p-6 pt-0">
        <vx-card class="m-0 my-8">
          <h2>Change Content</h2>
          <vs-row>
            <vs-col vs-w="12">
              <label for="content" class="font-bold">Content</label>
              <vs-textarea
                v-model="content"
                class="w-full"
                name="content"
                validate="required"
              />
            </vs-col>
          </vs-row>
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button @click="saveText" color="primary" type="filled"
            >Save</vs-button
          >
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import { jsPDF } from "jspdf";
import {
  WidthType,
  BorderStyle,
  Document,
  Paragraph,
  Packer,
  TextRun
} from "docx";
import { saveAs } from "file-saver";
import JsPDFAutotable from "jspdf-autotable";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import _ from "lodash";

export default {
  components: {
    Datepicker,
    JsPDFAutotable
  },
  data() {
    return {
      clientUrl: process.env.VUE_APP_CLIENT,
      appURL: process.env.VUE_APP_DOMAIN,
      showLayout: true,
      floatLayout: false,
      dateFormat: "MMMM",
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 12,
        page: 1
      },
      roomId: "",
      centerId: "",
      centreName: "",
      startDate: "",
      endDate: new Date(),
      timePeriod: "",
      outComes: [],
      coreSkills: [],
      coreSkillsName:"",
      coreSkillsCount:0,
      rooms: [],
      roomActivities: [],
      roomActivitiesCount: 0,
      totalRooms: 0,
      totalCompletedActivity:0,
      totalRunningActivity:0,
      totalCompletedFollowUp:0,
      totalRunningFollowUp:0,
      initialLoad: true,
      activities: [],
      adminList: [],
      teacherCount: 0,
      childStory: {},
      fastChecks: [],
      developmentLogs: [],
      developmentLogsCount: 0,
      selfAssessmentRooms: [],
      editTextPopup: false,
      editTextId: "",
      content: "",
      dynamicText: "",
      categories: [],
      eylfChecklists: [],
      eylfChecklistOutcomes: [],
      childWithFastchecksScore: 0,

      leadTeachers: [],
      safetyCoreskillId: "5eff1887b1ad2e55e6d89c7d",
      agilityCoreskillId: "5ee34c147bb36010d8462341",
      relationalCoreskillId: "5ee34c147bb36010d8462341",
      reciprocalPlayCoreskillId: "5ec758f93192c7392865cdb3",
      selfRegulationCoreskillId: "5ec758f93192c7392865cdaf"
    };
  },
  methods: {
    ...mapActions("centerAdmin", [
      "deleteCenterAdminRoom",
      "getNqsOutcomeList",
      "getNqsActivitiesList",
      "getCenterAdminTeacherList",
      "getNqsChildStoryCount",
      "getNqsFastCheck",
      "getNqsTeacherDevelopmentLogs",
      "getNqsSelfAssessmentsCountByCenterId",
      "generateNQS",
      "getNqsChildrenHavingFastCheckScore"
    ]),
    ...mapActions("center", [
      "getCenterById",
      "getDirectorCenterDetailList",
      "getLearningCenterRooms",
      "getLearningCenterRoomsForNqs",
      "getDirectorCenterAdminList",
      "fetchNqsContentByCenterId",
      "manageNqsContentByCenterId",
      "fetchEylfChecklistByCenterId"
    ]),
    ...mapActions("eylfCategory", [
      "fetchEylfCategoryForTeacherByLearningCenterId"
    ]),

    generatePDF2() {
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let startDate =
        this.startDate.getFullYear() +
        "-" +
        parseInt(this.startDate.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-01";
      let endDate =
        lastDay.getFullYear() +
        "-" +
        parseInt(lastDay.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-" +
        lastDay.getDate();
      let params = `?learningCenterId=${this.centerAdmin.learningCenterId}&startDate=${startDate}&endDate=${endDate}`;
      this.$vs.loading();
      this.generateNQS(params)
        .then(result => {
          this.$vs.loading.close();

          const blob = new Blob([result.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `NQS-REPORT-${startDate}-${endDate}`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(err => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    async getCategories(centerId) {
      await this.fetchEylfCategoryForTeacherByLearningCenterId(centerId)
        .then(res => {
          this.categories = res.data.data;
        })
        .catch(err => console.log(err));
    },
    async getEYLFList() {
      let startDate =
        this.startDate.getFullYear() +
        "-" +
        parseInt(this.startDate.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-01";
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let endDate =
        lastDay.getFullYear() +
        "-" +
        parseInt(lastDay.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-" +
        lastDay.getDate();

      let params = `${this.centerId}?stateId=${this.centerAdmin.learningCenter.address.state}&startDate=${startDate}&endDate=${endDate}`;
      await this.fetchEylfChecklistByCenterId(params).then(res => {
        this.eylfChecklists = res.data.data;
      });
    },
    checkListByCategoryId(categoryId) {
      return _.filter(this.eylfChecklists, function(item) {
        return item.eylfChecklists.categoryId === categoryId;
      });
    },
    editText() {
      this.editTextPopup = true;
    },
    async saveText() {
      let obj = {
        centerId: this.centerId,
        data: {
          content: this.content,
          learningCenterId: this.centerId
        }
      };
      await this.manageNqsContentByCenterId(obj)
        .then(res => {
          if (res.status === 200) this.dynamicText = this.content;
        })
        .catch(err => {
          console.log(err);
        });
      this.editTextPopup = false;
    },
    async getSelfAssessment() {

      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );

      let startDate =  this.startDate.getFullYear() + "-" +
          parseInt(this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-01";
          
        let endDate = lastDay.getFullYear() + "-" +
          parseInt(lastDay.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-" +
          lastDay.getDate();


          let params = "?centerId=" + this.centerId + "&startDate=" + startDate + "&endDate=" + endDate;

      await this.getNqsSelfAssessmentsCountByCenterId(params)
        .then(res => {
          this.selfAssessmentRooms = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getTeacherDevelopmentLogs() {

      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );

      let startDate =  this.startDate.getFullYear() + "-" +
          parseInt(this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-01";
          
        let endDate = lastDay.getFullYear() + "-" +
          parseInt(lastDay.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-" +
          lastDay.getDate();


          let params = "?centerId=" + this.centerId + "&startDate=" + startDate + "&endDate=" + endDate;


      await this.getNqsTeacherDevelopmentLogs(params)
        .then(res => {
          this.developmentLogs = res.data.data;
          if (this.developmentLogs) {
            this.developmentLogsCount = _.sumBy(this.developmentLogs, function(
              o
            ) {
              return o.developmentLogs.length;
            });
          }
        })
        .catch(err => console.log(err));
    },
    async getFastCheckCount() {
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let startDate =
        this.startDate.getFullYear() +
        "-" +
        parseInt(this.startDate.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-01";
      let endDate =
        lastDay.getFullYear() +
        "-" +
        parseInt(lastDay.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-" +
        lastDay.getDate();
      let id = `${this.centerId}?startDate=${startDate}&endDate=${endDate}`;
      await this.getNqsFastCheck(id)
        .then(res => {
          this.fastChecks = res.data.data;
        })
        .catch(err => console.log(err));
    },
    async getChildStoryCount() {
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let startDate =
        this.startDate.getFullYear() +
        "-" +
        parseInt(this.startDate.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-01";
      let endDate =
        lastDay.getFullYear() +
        "-" +
        parseInt(lastDay.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-" +
        lastDay.getDate();
      let id = `${this.centerId}?startDate=${startDate}&endDate=${endDate}`;
      await this.getNqsChildStoryCount(id)
        .then(res => {
          this.childStory = res.data.data;
        })
        .catch(err => console.log(err));
    },
    async getNQSActivities() {
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let data = {
        learningCenterId: this.centerAdmin.learningCenterId,
        state: this.centerAdmin.learningCenter.address.state,
        startDate:
          this.startDate.getFullYear() +
          "-" +
          parseInt(this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-01",
        endDate:
          lastDay.getFullYear() +
          "-" +
          parseInt(lastDay.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-" +
          lastDay.getDate()
      };
      await this.getNqsActivitiesList(data)
        .then(result => {
          this.activities = result.data.data;

          let coreskills = [];
          _.forEach(this.activities, function(value) {
            coreskills.push(value.coreSkillsDetails.name)
          });
          coreskills =  _.union(coreskills)
          this.coreSkillsCount = coreskills.length;

          let lastCoreskill = coreskills.pop();
          this.coreSkillsName = coreskills.join(', ') + " and " + lastCoreskill;

          })
        .catch(error => console.log(error));
    },

    async getNqsChildrenHavingFastCheckGreaterOrEuqalThree() {
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let startDate =
        this.startDate.getFullYear() +
        "-" +
        parseInt(this.startDate.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-01";
      let endDate =
        lastDay.getFullYear() +
        "-" +
        parseInt(lastDay.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-" +
        lastDay.getDate();
      let id = `?learningCenterId=${this.centerId}&startDate=${startDate}&endDate=${endDate}`;
      await this.getNqsChildrenHavingFastCheckScore(id)
        .then(res => {
          let result = res.data.data;
          this.childWithFastchecksScore = result.length;
        })
        .catch(err => console.log(err));
    },

    async getNQSReport() {
      let self = this;
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let data = {
        learningCenterId: this.centerAdmin.learningCenterId,
        state: this.centerAdmin.learningCenter.address.state,
        startDate:
          this.startDate.getFullYear() +
          "-" +
          parseInt(this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-01",
        endDate:
          lastDay.getFullYear() +
          "-" +
          parseInt(lastDay.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-" +
          lastDay.getDate()
      };

      await this.getNqsOutcomeList(data)
        .then(async res => {
          let result = await res.data.data;

          if (_.size(result) > 0) {
            await _.forEach(result, async (elem, i) => {
              /** core skills */
              if ("undefined" != typeof elem.coreSkills) {
                self.coreSkills.push(elem.coreSkills);
              }
              /**
               * Room
               */

              if ("undefined" != typeof elem.roomId) {
                if (elem.roomDetails.length > 0) {
                  let element = {
                    roomId: elem.roomId,
                    roomName: elem.roomDetails[0].name,
                    activity: elem.name,
                    count: 0
                  };

                  if (
                    "undefined" !=
                    typeof _.find(self.roomActivities, ["roomId", elem.roomId])
                  ) {
                    let index = _.findIndex(self.roomActivities, [
                      "roomId",
                      elem.roomId
                    ]);
                    self.roomActivities[index].count =
                      parseInt(self.roomActivities[index].count) + 1;
                  } else {
                    self.roomActivities.push(element);
                  }
                }
              }

              /** eylf */

              if ("undefined" != typeof elem.eylf) {
                await _.forEach(elem.eylf, (el, i) => {
                  /*   outcome[el.eylfframeworks] = []; */
                  self.outComes.push(el);

                  if (
                    "undefined" !=
                    typeof _.find(self.outComes, [
                      "eylfframeworks",
                      el.eylfframeworks
                    ])
                  ) {
                    let index = _.findIndex(self.outComes, [
                      "eylfframeworks",
                      el.eylfframeworks
                    ]);

                    /**
                     * Learning stories number
                     */
                    if (
                      "undefined" !=
                      typeof self.outComes[index].learningStoriesNumber
                    ) {
                      self.outComes[index].learningStoriesNumber =
                        parseInt(self.outComes[index].learningStoriesNumber) +
                        1;
                    } else {
                      self.outComes[index].learningStoriesNumber = 1;
                    }

                    /**
                     * Learning stories Room number
                     */

                    if (
                      "undefined" !=
                      typeof self.outComes[index].learningStoriesRooms
                    ) {
                      self.outComes[index].learningStoriesRooms.push(
                        elem.roomId
                      );
                      self.outComes[index].learningStoriesRooms = _.uniq(
                        self.outComes[index].learningStoriesRooms
                      );
                    } else {
                      self.outComes[index].learningStoriesRooms = [elem.roomId];
                    }

                    self.outComes[index].learningStoriesRoomNumber = _.size(
                      self.outComes[index].learningStoriesRooms
                    );
                  }
                });
              }
            });

            self.coreSkills = _.uniq(self.coreSkills, "_id");

            self.roomActivities = _.uniq(self.roomActivities, "roomId");
          } else {
            self.dataNotFound();
          }
        })
        .catch(err => {
          console.log(err);
        });

      //await self.getLCRooms();
    },

    async getLCRooms() {
      let self = this;


      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );

      let startDate =  this.startDate.getFullYear() + "-" +
          parseInt(this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-01";
          
        let endDate = lastDay.getFullYear() + "-" +
          parseInt(lastDay.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-" +
          lastDay.getDate();

      let params = "?id=" + self.centerId + "&startDate=" + startDate + "&endDate=" + endDate;

      self.totalRunningActivity = 0;
      self.totalCompletedActivity = 0;
      self.totalRunningFollowUp = 0;
      self.totalCompletedFollowUp = 0;
      await self
        .getLearningCenterRoomsForNqs(params)
        .then(res => {
          /** remove first index  as it does not contain any room value */
          self.rooms = res.data.data;

          _.forEach(self.rooms, (o, i) => {
            self.totalCompletedActivity =  self.totalCompletedActivity + o.completedActivitiesCount;
          });

           _.forEach(self.rooms, (o, i) => {
            self.totalRunningActivity =  self.totalRunningActivity + o.runingActivitiesCount;
          });

          _.forEach(self.rooms, (o, i) => {
            self.totalCompletedFollowUp =  self.totalCompletedFollowUp + o.completedFollowUpActivity;
          });

          _.forEach(self.rooms, (o, i) => {
            self.totalRunningFollowUp =  self.totalRunningFollowUp + o.runningFollowUpActivity;
          });
  
          //_.pullAt(self.rooms, [0]);
          // _.forEach(self.rooms, (o, i) => {
          //   _.forEach(self.roomActivities, (act, j) => {
          //     if (act.roomId == o._id) {
          //       console.log(act,"act")
          //        console.log(act.roomId,"roomId")
          //         console.log(act.count,"count")
          //         self.rooms[i].count =  "0000";
          //         console.log(self.rooms[i],"tt");
          //         self.rooms[i].count =  act.count;
          //       o.count = act.count;
          //     } else {
          //       o.count = 0;
          //     }
          //   });
          // });

          // self.totalRooms = _.sumBy(self.rooms, o => {
          //   return o.count;
          // });
        })
        .catch(err => {
          console.log(err);
        });
    },
    dataNotFound() {
      Swal.fire({
        title: "Record not found under given date range",
        text: "Please change the date range",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok"
      }).then(result => {
        if (result.value) {
          return "";
          // console.log(result);
        }
      });
    },
    /*Get Active Activities*/
    // getActiveActivities() {
    //   const activeActivity = _.filter(this.activities, function(o) {
    //     return o.status.slug === "new" && !o.parentActivityId;
    //   });
    //   return activeActivity.length;
    // },
    /*Get Active Activities*/
    getCompletedActivities(list = null) {
      const activeActivity = _.filter(this.activities, function(o) {
        return o.status.slug === "complete" && !o.parentActivityId;
      });
      return list ? activeActivity : activeActivity.length;
    },
    getTopThreeCompletedActivities() {
      const a = this.getCompletedActivities(true);
      const b = _.orderBy(a, "createdAt", "desc");
      return _.slice(b, 0, 3);
    },
    getClosedFollowUps() {
      const activeActivity = _.filter(this.activities, function(o) {
        return o.isCompleted;
      });
      return activeActivity.length;
    },
    getCoreSkillActivities(coreSkill, count) {
      let activeActivity = _.filter(this.activities, function(o) {
        return o.coreSkillsDetails.name == coreSkill;
      });

       activeActivity = _.filter(activeActivity, function(o) {
          return o.status.slug == "complete";
        });


      if (count) {
        const a = _.orderBy(activeActivity, "createdAt", "desc");
        return _.slice(a, 0, count);
      } else {
        return activeActivity.length;
      }
    },
    // getCoreSkillActivities(coreSkill, count) {
    //   let activeActivity = _.filter(this.activities, function(o) {
    //     return o.coreSkillsDetails.name == coreSkill;
    //   });
    //   if (count) {
    //     activeActivity = _.filter(activeActivity, function(o) {
    //       return o.status.slug == "complete";
    //     });
    //     const a = _.orderBy(activeActivity, "createdAt", "desc");
    //     return _.slice(a, 0, count);
    //   } else {
    //     return activeActivity.length;
    //   }
    // },

    getWaysOfLearningActivities(wof) {
      let activeActivity = _.filter(this.activities, function(o) {
        return o.waysOfLearning.name == wof;
      });

        activeActivity = _.filter(activeActivity, function(o) {
          return o.status.slug == "complete";
        });

      return activeActivity.length;
    },
    // getWaysOfLearningActivities(wof) {
    //   const activeActivity = _.filter(this.activities, function(o) {
    //     return o.waysOfLearning.name == wof;
    //   });
      
    //   return activeActivity.length;
    // },
    getStreamActivities(stream) {
      let activeActivity = _.filter(this.activities, function(o) {
        return o.streams.name == stream;
      });

      activeActivity = _.filter(activeActivity, function(o) {
          return o.status.slug == "complete";
        });



      return activeActivity.length;
    },
    async getAdminList() {
      await this.getDirectorCenterAdminList(this.centerId)
        .then(res => {
          this.adminList = res.data.data;
        })
        .catch(err => console.log(err));
    },
    async getTeacherData() {
      this.dataTableParams.learningCenterId = this.centerAdmin.learningCenterId;
      await this.getCenterAdminTeacherList(this.dataTableParams)
        .then(res => {
          this.teacherCount = res.data.data.pagination.total
            ? res.data.data.pagination.total
            : 0;

          let teachers = res.data.data.docs;
          this.leadTeachers = _.filter(teachers, { role: "leadTeacher" });
        })
        .catch(err => {
          console.log(err);
        });
    },
    generatePrint() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("nqs-report-print-table")
        .innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      /*for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      stylesHtml += node.outerHTML;
      } */

      // Open the print window
      const printTable = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      printTable.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap" rel="stylesheet">
                </head>
                <body>
                <style>
                    body{
                        font-family: 'Open Sans', sans-serif;
                    }
                    @media print {
                        body{
                            margin: 0.5cm;
                        }
                        thead{
                            padding: 25px;
                        }
                        @page {
                          margin: 0.5cm;
                        }
                        thead tr{

                            border-style: solid;
                            border-width: 2px;
                            border-color: #cccccc;
                        }
                    }

                </style>
                    ${prtHtml}
                </body>
                </html>`);

      printTable.document.close();

      printTable.print();
    },
    setOptions() {
      return {
        orientation: "portait",
        unit: "px",
        format: "a4"
      };
    },
    getPDFName() {
      let d1 = this.startDate,
        d2 = this.endDate;

      let date2 =
          d2.getFullYear().toString() +
          d2
            .getMonth()
            .toString()
            .padStart(2, 0) +
          d2
            .getDate()
            .toString()
            .padStart(2, 0),
        date1 =
          d1.getFullYear().toString() +
          d1
            .getMonth()
            .toString()
            .padStart(2, 0) +
          d1
            .getDate()
            .toString()
            .padStart(2, 0);

      return date2 + "-" + date1;
    },
    async getSavedText() {
      await this.fetchNqsContentByCenterId(this.centerId)
        .then(res => {
          this.dynamicText = res.data.data
            ? res.data.data.content
            : "Please update text.";
          this.content = res.data.data ? res.data.data.content : "";
        })
        .catch(err => console.log(err));
    }
  },
  watch: {
    startDate(obj) {
      if ("" != this.startDate && "" != this.endDate) {
        if (!this.initialLoad) {
          this.getEYLFList();
          this.getNQSActivities();
          this.getChildStoryCount();
          this.getFastCheckCount();
          this.getNqsChildrenHavingFastCheckGreaterOrEuqalThree();
          this.getLCRooms();
          this.getTeacherDevelopmentLogs();
          this.getSelfAssessment();
        }

        this.timePeriod = this.monthDiff + " months";
      }
    },
    endDate(obj) {
      if ("" != this.startDate && "" != this.endDate) {
        if (!this.initialLoad) {
          this.getEYLFList();
          this.getNQSActivities();
          this.getChildStoryCount();
          this.getFastCheckCount();
          this.getNqsChildrenHavingFastCheckGreaterOrEuqalThree();
          this.getLCRooms();
          this.getTeacherDevelopmentLogs();
          this.getSelfAssessment();

        }
        this.timePeriod = this.monthDiff + " months";
      }
    }
  },
  created() {
    let today = new Date(),
      lastThreeMonths = new Date();
    this.centerId = this.centerAdmin.learningCenterId;
    this.centreName = this.centerAdmin.learningCenter.name;

    lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
    this.startDate = lastThreeMonths;
    this.getNQSReport();
    this.getCategories(this.centerId);
    this.getAdminList();
    this.getTeacherData();
    this.getChildStoryCount();
    this.getFastCheckCount();
    this.getTeacherDevelopmentLogs();
    this.getSelfAssessment();
    this.getSavedText();
    this.getNqsChildrenHavingFastCheckGreaterOrEuqalThree();

    this.$vs.loading.close();
    this.initialLoad = false;
  },
  computed: {
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    },
    monthDiff() {
      let d1 = this.startDate,
        d2 = this.endDate;

      return (
        d2.getMonth() -
        d1.getMonth() +
        12 * (d2.getFullYear() - d1.getFullYear()) + 1
      );
    }
  }
};
</script>
